import './header.css';

function Header() {
  return (
    <div id="index">
       <div id="header">
      <h2 id="texto_header">TUDO SOBRE COMO MONTAR</h2>
      <h2 id="texto_header_bottom">ASSISTÊNCIA VEICULAR 24H</h2>
      </div>
    </div>
  );
}

export default Header;
