
import React, {useRef, useState } from "react";
import './download.css';
import emailjs from "@emailjs/browser";
import EBOOK from "../assets/image/002.png";

    const  Download= () => {
        const form = useRef();
        const [done, setDone] = useState(false)
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs
            .sendForm(
              "service_n8sn62u",
              "template_02n2fdr",
              form.current,
              "user_KQJz9UxEipqsznwIHHrMc"
            )
            .then(
              (result) => {
                console.log(result.text);
                setDone(true);
              },
              (error) => {
                console.log(error.text);
              }
            );
        };
      


  return (


<div id="download">
<div id="esquerda_download">
<center>
     <img src={EBOOK} id="ebook_1"
        style={{
          marginTop:'-90px',
          marginLeft:'20px',
        }}
        alt="" />
        </center>
        <form ref={form} onSubmit={sendEmail}>
       
          <input required id="input_envio_download"type="text" name="nome" className="user"  placeholder="seu nome completo"/>
          <input required id="input_envio_download"type="number" name="whatsapp" className="user" placeholder="seu numero de whatsapp"/>
          <input required id="input_envio_download" type="email" name="email" className="user" placeholder="seu melhor email"/>
          <input 
          id="button_donwload_contato" type="submit" value=" BAIXAR EBOOK GRÁTIS! " />

          <a id="donwload_gratis_pdf" href="https://drive.google.com/file/d/1LSbqSeQo1A8bxMvabmhioQqAJsH9DAJs/view?usp=share_link" target="blank_">{done && " CLIQUEI AQUI PARA BAIXAR!! "}</a>
 
     
          <div
            className="blur c-blur1"
            style={{ background: "var(--purple)" }}
          ></div>
           
        </form>
        
      </div>
      <div id="direita_download">
        <h2 id="texto_esq_ass">EBOOK</h2>
        <h2 id="texto_esq_bottom">GRATUITO<span id="_24h"></span></h2>
        <h2 id="texto_esq_bottom">TUDO<span id="_24h">SOBRE</span></h2>
        <h2 id="texto_esq_bottom">COMO<span id="_24h">MONTAR</span></h2>
        <h2 id="texto_esq_bottom">SUA<span id="_24h">ASSISTÊNCIA</span></h2>
        <h2 id="texto_esq_bottom">24<span id="_24h">HORAS</span></h2>
        <p id="ceo_texto">Desenvolvido pelo Ceo Gilberto Bispo 2023 | atise.com.br</p></div></div>
  
    
  );
};

export default Download;

