import './assistencia.css';
import { Outlet, Link } from "react-router-dom";
import gilberto from '../assets/image/003.png';
import gilberto_foto from '../assets/image/004.png';

function Assistencia() {
  return (
    <div id="Assis">
        <div id="esquerda">
            <h2 id="texto_esq_ass">ASSISTÊNCIA</h2>
            <h2 id="texto_esq_bottom">VEICULAR <span id="_24h">24H</span></h2>
            <h5 id="texto_esq_bottom_ass">Conto tudo no e-book para você montar sua própria assistência veicular 24h</h5>
            <center> 
                <img id="rastreamento_" src={gilberto} />
                <div id="button_style_ass">
                    <a id="donwload_" href="download_ebook_mentoria_assistencia_24horas_passo_passo_gilberto_bispo">
                    <span id="download_gratis">DOWNLOAD GRÁTIS AQUI</span><br />Aproveite enquanto é grátis</a></div></center>
                    </div><div id="direita">
                        <img id="gilberto" src={gilberto_foto} />
                    </div>
                    </div>
 );
}
export default Assistencia;
