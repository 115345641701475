import './Layout.css';
import Header from '../header/Header';
import Assistencia from '../assistencia/assistencia';
import Passo from '../passo/passo';
import Sobre from '../sobre/sobre';
import Ceo from '../ceo/ceo';
import Ebook from '../ebook/ebook';

function Layout() {
return (
  <div>
<Header />
<Assistencia />
<Passo  />
<Sobre />
<Ceo />
<Ebook />
</div>



  );
}

export default Layout;
