import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from './Layout/Layout';

import Download from './download/download';
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="download_ebook_mentoria_assistencia_24horas_passo_passo_gilberto_bispo" element={<Download />} />

      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);