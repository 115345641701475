

import './ebook.css';
import ebook from '../assets/image/002.png';
function Ebook() {
  return (
<div id="ebook"><div id="esquerda_ebook">
    <h2 id="texto_esq_ebook">E-BOOK GRATUITO</h2>
    <h5 id="texto_esq_bottom_ebook">Tudo sobre como montar Assistência Veicular 24H começando do zero! Passo a Passo</h5>
    <div id="button_style_ass"><a id="donwload_" href="/download_ebook_mentoria_assistencia_24horas_passo_passo_gilberto_bispo"><span id="download_gratis">DOWNLOAD GRÁTIS AQUI</span><br />Aproveite enquanto é grátis</a></div>
    </div><div id="direita">
    <img id="ebook_" src={ebook} /></div></div>
  );
}

export default Ebook;

