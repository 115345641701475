

import './ceo.css';

function Ceo() {
  return (

<div id="ceo"><div id="padding_"><div id="ceo_entenda"><h1 id="texto_ceo">Sou CEO que desenvolveu um método de atendimento de assistência veicular 24h, onde nosso serviço é customizado para atende-lô de forma ágil e prática. Temos tecnologia low-touch, com poucos toques e informações simples, é possivel acionar os serviços de assistência veicular 24h em qualquer estado do Brasil.</h1><h1 id="texto_ceo">Ceo Gilberto Bispo | atise.com.br 2023</h1></div></div></div>
  );
}

export default Ceo;

