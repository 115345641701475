

import './passo.css';

function Passo() {
  return (


       <div id="passo"><div id="padding_"><div id="passo_entenda"><h1 id="texto_entenda">Entenda o passo a passo para criar sua assistência veicular 24h do zero.</h1></div></div></div>

  );
}

export default Passo;

